<template>
  <div :class="$style.notifications">
    <Title text="Уведомления" position="right" />
    <div :class="$style.table">
      <div :class="$style.table__header">
        <div
          :class="$style.table__header_column"
          v-for="headerColumn in headerColumns"
          :key="headerColumn.id"
        >
          {{ headerColumn.title }}
          <img v-if="headerColumn.value" src="@/assets/icons/arrow.svg" alt="arrow" />
        </div>
      </div>
      <div :class="$style.table__content">
        <div
          v-for="notification in totalItems"
          :key="notification.id"
          :class="$style.table__content_wrapper"
        >
          <div :class="$style.table__content_column">
            {{ notification.id ? notification.id : '' }}
          </div>
          <div :class="$style.table__content_column">
            {{ moment(notification.createdAt).format('DD-MM-YYYY  LT') }}
          </div>
          <div :class="$style.table__content_column">
            {{ notification.text }}
          </div>

          <div :class="$style.table__content_column">
            <Button
              v-if="notification.typeId == 21"
              type="table"
              @click="connectToChat(JSON.parse(notification.data).message.chatId)"
            >
              Перейти к чату
            </Button>
          </div>
        </div>
        <div :class="$style.table__content_wrapperLine">
          <div :class="$style.table__content_line" />
          <div :class="$style.table__content_line" />
          <div :class="$style.table__content_line" />
          <div :class="$style.table__content_line" />
        </div>
      </div>
    </div>
    <pagination
      :pageCount="pageCount"
      :prevText="''"
      :click-handler="onPageChange"
      :nextText="''"
      :containerClass="'pagination'"
      :page-class="'page'"
      v-show="notifications.length > 10"
      :initial-page="this.$route.query.page - 1"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Moment from 'moment'

import Title from '@/basic/Title'
import Button from '@/basic/Button'
import orderBy from 'lodash/orderBy'
import appeals from '@/api/appeals'
import paginationMixin from '@/mixins/pagination.mixin'

export default {
  name: 'Notifications',
  components: {
    Title,
    Button
  },
  data() {
    return {
      notifications: [],
      headerColumns: [
        {
          id: 1,
          value: 'id',
          title: 'id'
        },
        {
          id: 2,
          value: 'createdAt',
          title: 'Дата'
        },
        {
          id: 3,
          value: 'name',
          title: 'Текст'
        },
        {
          id: 4,
          value: '',
          title: ''
        }
      ]
    }
  },
  mixins: [paginationMixin],
  watch: {
    filterValue: {
      handler() {
        this.setupPagination(this.notifications)
      }
    }
  },
  /*watch: {
    notifications: {
      handler() {
        console.log(this.notifications)
      }
    }
  },*/
  async mounted() {
    const res = await appeals.getNotificationsPage()
    this.notifications = orderBy(res.data.result, 'id', this.sortMethod)
    this.setupPagination(this.notifications)
  },
  computed: {
    // ...mapGetters(['notifications']),
    moment() {
      return Moment
    }
  },
  methods: {
    sortNotifications(sortValue) {
      this.toggleSortMethod()
      this.notifications = orderBy(this.notifications, sortValue, this.sortMethod)
    },
    toggleSortMethod() {
      this.sortMethod = this.sortMethod === 'asc' ? 'desc' : 'asc'
    },
    setCurrentPage(pageNumber) {
      this.currentPage = pageNumber
    },
    ...mapMutations(['setChat']),
    connectToChat(chatId) {
      this.$socket.emit('dashboard-chats-enter', { chatId: chatId }, (data) => {
        if (data && data.success) {
          this.setChat(data.result)
          this.$router.push(`chats/${chatId}`)
        }
      })
    }
  }
}
</script>

<style module lang="scss">
@import '@/assets/styles/colors.scss';

.notifications {
  .table {
    width: 100%;
    //height: 85%;
    border: 1px solid $table-border;

    &__header {
      display: flex;
      justify-content: space-between;
      height: 2.5rem;
      border-bottom: 1px solid $table-border;

      &_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 1.125rem;
        font-weight: 500;
        img {
          cursor: pointer;
          padding: 10px;
        }

        &:first-child {
          width: 7.5%;
        }

        &:nth-child(n + 2) {
          width: 29%;
        }

        &:last-child {
          border: none;
        }
      }
    }
    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 95%;

      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        //height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }

      &_column {
        display: flex;
        align-items: center;
        //height: 2.5rem;
        padding: 0 1rem;
        font-size: 0.875rem;
        font-weight: 500;

        &:first-child {
          width: 7.5%;
          justify-content: center;
        }

        &:nth-child(n + 2) {
          width: 29%;
        }

        &:last-child {
          border: none;
        }

        &_active {
          background: $red;
          border-right: 1px solid $table-border;
        }
      }

      &_wrapperLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &_line {
        border-right: 1px solid $table-border;

        &:first-child {
          width: 7.5%;
        }

        &:nth-child(n + 2) {
          width: 29%;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>
