import Vue from 'vue'

export default {
  getNotifications() {
    return Vue.axios.post('/dashboard/notifications/list', {
      filter: {
        typeId: [12, 18]
      }
    })
  },
  getNotificationsPage() {
    return Vue.axios.post('/dashboard/notifications/list', {
      filter: {
        typeId: [1, 2, 5, 13, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 32, 38, 42, 16, 40]
      }
    })
  },
  setPost(id) {
    return Vue.axios.patch('/dashboard/notifications/' + id, { id: id })
  }
}
